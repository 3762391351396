// istanbul ignore file
import { translate, translateFormatter } from '@manomano-internal/i18n/esm/placeholders';
export const arrows = translate({
  "titleLeft": "Pr\xE9c\xE9dent",
  "titleRight": "Suivant"
});
export const price = translate({
  "vatExclLabel": "HT",
  "vatInclLabel": "TTC"
});
export const rating = {
  ariaLabel: (value: number) => translateFormatter<{
    value: number;
  }>("{value} sur 5", undefined, undefined, "fr").format({
    value
  }) as string,
  reviewsLabel: (value: number) => translateFormatter<{
    value: number;
  }>("sur {value, plural, =1 {# avis} other {# avis}}", undefined, undefined, "fr").format({
    value
  }) as string
};
export const pagination = translate({
  "previousPageAriaLabel": "Page pr\xE9c\xE9dente",
  "nextPageAriaLabel": "Page suivante"
});
export const counter = translate({
  "decrementAriaLabel": "Diminuer",
  "incrementAriaLabel": "Augmenter"
});
export const inputPhone = {
  countriesLabels: translate({
    "BE": "Belgique",
    "DE": "Allemagne",
    "ES": "Espagne",
    "FR": "France",
    "GB": "Royaume Uni",
    "IT": "Italie",
    "LU": "Luxembourg",
    "CH": "Suisse"
  })
};