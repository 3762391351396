import {
  Svg,
  type SvgProps,
} from '@manomano-internal/tamagoshi-icons/esm/SvgOptimizer/Svg';
import type { FunctionComponent } from 'react';

export const CheckCircleOutline: FunctionComponent<SvgProps> = (props) => (
  <Svg
    optimizationId="iconsSystemCheckCircleOutline"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM15.88 8.29L10 14.17L8.12 12.29C7.73 11.9 7.1 11.9 6.71 12.29C6.32 12.68 6.32 13.31 6.71 13.7L9.3 16.29C9.69 16.68 10.32 16.68 10.71 16.29L17.3 9.7C17.69 9.31 17.69 8.68 17.3 8.29C16.91 7.9 16.27 7.9 15.88 8.29Z"
      fill="#0C193A"
    />
  </Svg>
);

CheckCircleOutline.displayName = 'CheckCircleOutline';
