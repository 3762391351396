import classnames from 'classnames';
import { type FunctionComponent } from 'react';

import { Swap } from '@/core/tamagoshi/icons/Swap';

import styles from './SecondHandTag.module.scss';

export interface SecondHandTagProps {
  className?: string;
  label: string;
  small?: boolean;
}

export const SecondHandTag: FunctionComponent<SecondHandTagProps> = ({
  className,
  label,
  small = false,
}) => (
  <div
    className={classnames(
      styles.secondHandTag,
      { [styles.small]: small },
      className,
    )}
  >
    <Swap />
    {label}
  </div>
);
