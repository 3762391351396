import { type ElementType } from 'react';
import { twJoin } from 'tailwind-merge';

import { StarFullGrayIcon } from '@/core/tamagoshiTailwind/icons/system/StarFullGrayIcon';
import { StarFullYellowIcon } from '@/core/tamagoshiTailwind/icons/system/StarFullYellowIcon';
import { StarHalfIcon } from '@/core/tamagoshiTailwind/icons/system/StarHalfIcon';
import { RequireAllOrNone } from '@/core/tamagoshiTailwind/util/utils.type';

type CommonRatingProps<S extends string> = {
  /** Display the amount of reviews for the rating */
  reviewsCount?: number;
  /** The value of the rating. Floating number between 0 and 5.0 included */
  value: number;
  /** The size of the rating */
  size: S;
  /** Function to generate the aria-label for accessibility, based on the rating value */
  'aria-label': (value: number) => string;
};

type SizeSProps = CommonRatingProps<'S'>;

type SizeMProps = CommonRatingProps<'M'> &
  RequireAllOrNone<{
    /** Display the amount of reviews for the rating */
    reviewsCount: number;
    /** Callback function to be called when the component is clicked */
    onClick: () => void;
    /** Function to generate the reviews label to show based on the count of reviews */
    reviewsLabel: (value: number) => string;
  }>;

export type RatingProps = SizeSProps | SizeMProps;

const STYLES = {
  M: { root: 'font-semibold underline', star: 'size-[20px]' },
  S: { root: 'font-regular', star: 'size-[16px]' },
} as const satisfies Record<RatingProps['size'], unknown>;

/**
 * Render a rating value between 0 and 5 included with pre-filled stars.
 * Support half points but clamp the value between 0 and 5.
 *
 * Use the translation keys available in Phrase for the labels
 * - `designSystem.rating.ariaLabel`
 * - `designSystem.rating.reviewsLabel`
 *
 * See the related [Figma](figma) for more details.
 *
 * [figma]: https://www.figma.com/design/KAiRlBccr6bFYmKby9wQrR?node-id=15439-3560
 */
export function Rating({
  reviewsCount,
  size,
  value,
  'aria-label': ariaLabel,
  ...props
}: RatingProps) {
  const renderStars = (numberOfStars: number, Component: ElementType) =>
    Array(numberOfStars)
      .fill(null)
      .map((_, index) => (
        <Component className={STYLES[size].star} key={index} />
      ));

  const clampedValue = Math.max(0, Math.min(5, value));
  const roundedValue = Math.round(clampedValue * 10) / 10;
  const truncatedValue = Math.floor(roundedValue * 2) / 2;
  const label = ariaLabel(roundedValue);

  const numberOfFilledStars = Math.floor(truncatedValue);
  const hasHalfFilledStar = Boolean(
    Math.ceil(truncatedValue) - numberOfFilledStars,
  );
  const numberOfEmptyStars =
    5 - numberOfFilledStars - Number(hasHalfFilledStar);

  const Element = 'onClick' in props ? 'button' : 'span';

  return (
    <Element
      className={twJoin(
        'inline-flex items-center gap-xs text-small',
        STYLES[size].root,
      )}
      onClick={'onClick' in props ? props.onClick : undefined}
    >
      <span aria-label={label} className="flex" title={label}>
        {renderStars(numberOfFilledStars, StarFullYellowIcon)}
        {hasHalfFilledStar && renderStars(1, StarHalfIcon)}
        {renderStars(numberOfEmptyStars, StarFullGrayIcon)}
      </span>
      {reviewsCount !== undefined && (
        <>
          {size === 'S' && <span>{reviewsCount}</span>}
          {'reviewsLabel' in props && (
            <span>{props.reviewsLabel?.(reviewsCount)}</span>
          )}
        </>
      )}
    </Element>
  );
}

Rating.displayName = 'Rating';
