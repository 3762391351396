import { DEFAULT_TAG, type ProductCardProps } from '../ProductCard';

export function useProductCardErrors<
  Tag extends React.ElementType = typeof DEFAULT_TAG,
>({ narrow, vertical, merchandising }: Partial<ProductCardProps<Tag>>): void {
  if (narrow && merchandising?.includes('promoted')) {
    throw new Error(
      'Incompatible props: narrow and merchandising promoted are not compatible!',
    );
  } else if (
    vertical &&
    (merchandising?.includes('bestQualityOffer') ||
      merchandising?.includes('topSale') ||
      merchandising?.includes('promoted'))
  ) {
    throw new Error(
      'Incompatible props: vertical and merchandising props are not compatible!',
    );
  }
}
