import classnames from 'classnames';
import {
  type ComponentType,
  type FunctionComponent,
  type ReactElement,
} from 'react';

import { BrandImage } from './components/BrandImage';
import { EcoResponsibleTag } from './components/EcoResponsibleTag/EcoResponsibleTag';
import { type ImageProps } from './components/Image';
import { LocalFlag } from './components/LocalFlag/LocalFlag';
import { ProductImage } from './components/ProductImage/ProductImage';
import { type SizeType } from './interfaces';

import styles from './VisualDetails.module.scss';

export interface VisualDetailsProps {
  productTitle: string;
  imageSrc: string;
  imageLoading: 'eager' | 'lazy';
  provideImageDataSrc: boolean;
  className?: string;
  brandImageSrc?: string;
  brandName?: string;
  type?: SizeType;
  vertical?: boolean;
  light?: boolean;
  image?: ComponentType<ImageProps> | ReactElement<ImageProps>;
  productImage?: ComponentType<ImageProps> | ReactElement<ImageProps>;
  flag?: 'fr';
  ecoTagEnabled?: boolean;
}

export const VisualDetails: FunctionComponent<VisualDetailsProps> = ({
  productTitle,
  imageSrc,
  brandImageSrc,
  brandName,
  className,
  type,
  image,
  productImage,
  vertical = false,
  light = false,
  imageLoading,
  provideImageDataSrc,
  flag,
  ecoTagEnabled = false,
}) => {
  const rootClassnames = classnames(
    styles.root,
    {
      [styles.vertical]: vertical,
      [styles.promoted]: type === 'PROMOTED',
      [styles.withoutBrand]: !brandImageSrc,
      [styles.light]: light,
    },
    className,
  );
  const altText = brandName || '';

  const hasFlag = ['fr'].includes(flag || '');
  const logosClassNames = classnames(styles.logos, {
    [styles.withOneTag]: hasFlag || ecoTagEnabled,
    [styles.withFlagAndEcoTag]: hasFlag && ecoTagEnabled,
  });

  return (
    <div className={rootClassnames}>
      <ProductImage
        alt={productTitle}
        src={imageSrc}
        type={type}
        image={productImage || image}
        loading={imageLoading}
        provideDataSrc={provideImageDataSrc}
      />

      {(brandImageSrc || hasFlag || ecoTagEnabled) && (
        <div className={logosClassNames} role="contentinfo">
          {brandImageSrc && (
            <BrandImage
              className={styles.brand}
              alt={altText}
              src={brandImageSrc}
              vertical={vertical}
              light={light}
              image={image}
              loading={imageLoading}
              type={type}
            />
          )}
          {flag && <LocalFlag />}
          {ecoTagEnabled && <EcoResponsibleTag />}
        </div>
      )}
    </div>
  );
};

VisualDetails.displayName = 'VisualDetails';
