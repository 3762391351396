import classnames from 'classnames';
import { type FunctionComponent } from 'react';

import styles from './EcoResponsibleTag.module.scss';

interface EcoResponsibleTagProps {
  className?: string;
}

export const EcoResponsibleTag: FunctionComponent<EcoResponsibleTagProps> = ({
  className,
}) => {
  const rootClassnames = classnames(styles.root, className);

  return <div className={rootClassnames} aria-label="eco responsible" />;
};

EcoResponsibleTag.displayName = 'EcoResponsibleTag';
