import { type FunctionComponent } from 'react';

import { Text } from '../../../Text/Text';

export interface AlternativeModelProps {
  className?: string;
  label: string;
}

export const AlternativeModel: FunctionComponent<AlternativeModelProps> = ({
  label,
}) => (
  <Text type="tiny" weight="regular">
    {label}
  </Text>
);

AlternativeModel.displayName = 'AlternativeModel';
