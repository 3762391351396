import { Svg } from '@manomano-internal/tamagoshi-icons/esm/SvgOptimizer/Svg';

import { makeTamagoshiIcon } from '@/core/tamagoshiTailwind/util/makeTamagoshiComponent';

export const StarHalfIcon = makeTamagoshiIcon((props, ref) => (
  <Svg
    optimizationId="tama-star-half"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    ref={ref}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 17.6557L16.3649 20.3413C17.1642 20.8335 18.1424 20.1059 17.932 19.1858L16.7751 14.1356L20.6351 10.7331C21.3398 10.1125 20.9611 8.93558 20.0356 8.86068L14.9555 8.422L12.9676 3.65C12.61 2.78333 11.39 2.78333 11.0324 3.65L9.04451 8.4113L3.96443 8.84998C3.03887 8.92488 2.66023 10.1018 3.36492 10.7224L7.22494 14.1249L6.06798 19.1751C5.85763 20.0952 6.83578 20.8228 7.63513 20.3306L12 17.6557Z"
      fill="#D8DADF"
    />
    <mask
      id="mask0_17090_75282"
      maskUnits="userSpaceOnUse"
      x="3"
      y="3"
      width="9"
      height="18"
    >
      <rect x="3" y="3" width="9" height="18" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_17090_75282)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 17.6557L16.3649 20.3413C17.1642 20.8335 18.1424 20.1059 17.932 19.1858L16.7751 14.1356L20.6351 10.7331C21.3398 10.1125 20.9611 8.93558 20.0356 8.86068L14.9555 8.422L12.9676 3.65C12.61 2.78333 11.39 2.78333 11.0324 3.65L9.04451 8.4113L3.96443 8.84998C3.03887 8.92488 2.66023 10.1018 3.36492 10.7224L7.22494 14.1249L6.06798 19.1751C5.85763 20.0952 6.83578 20.8228 7.63513 20.3306L12 17.6557Z"
        fill="#FFB950"
      />
    </g>
  </Svg>
));

StarHalfIcon.displayName = 'StarHalfIcon';
