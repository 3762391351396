import { useState, type FunctionComponent } from 'react';

export type ImageProps = JSX.IntrinsicElements['img'] & {
  /**
   * Alternative text must be provided for accessibility purpose.
   * */
  alt: string;
  'data-src'?: string;
  'data-testid'?: string;
  /**
   * Images are lazy loaded by default.
   *
   * You must use 'eager' whether your image will be displayed above the fold,
   * it will make it be loaded immediately.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/img#attr-loading
   */
  loading?: 'eager' | 'lazy';
  /**
   * Width and height should always be provided. If you cannot, they must be
   * explicitly set to undefined.
   *
   * @see https://web.dev/optimize-cls/#images-without-dimensions
   *  */
  height: number | string | undefined;
  width: number | string | undefined;
};

export const Image: FunctionComponent<ImageProps> = ({
  alt,
  src,
  width,
  height,
  ...forwardedProps
}) => {
  const [hasError, setHasError] = useState(false);

  const fallbackSrc = `data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='${width}' height='${height}' %3E%3Cdefs%3E%3ClinearGradient id='g1'%3E%3Cstop stop-color='%23F5F6F7'/%3E%3Cstop offset='.983' stop-color='%23ECEDF0'/%3E%3Cstop offset='1' stop-color='%23ECEDF0'/%3E%3C/linearGradient%3E%3C/defs%3E%3Crect width='100%25' height='100%25' fill='url(%23g1)'/%3E%3C/svg%3E`;

  return (
    // eslint-disable-next-line react/forbid-elements
    <img
      alt={alt}
      src={hasError ? fallbackSrc : src}
      onError={() => setHasError(true)}
      width={width}
      height={height}
      {...forwardedProps}
    />
  );
};
