import {
  cloneElement,
  type ComponentType,
  type FunctionComponent,
  type ReactElement,
} from 'react';

import { SIZES } from '../constants';
import { type SizeType } from '../interfaces';
import { Image, type ImageProps } from './Image';

export interface BrandImageProps {
  alt: string;
  src: string;
  className?: string;
  vertical?: boolean;
  light?: boolean;
  image?: ComponentType<ImageProps> | ReactElement<ImageProps>;
  loading: 'eager' | 'lazy';
  type?: SizeType;
}

export const BrandImage: FunctionComponent<BrandImageProps> = ({
  alt,
  src,
  className,
  image,
  loading,
  type = 'DEFAULT',
}) => {
  const props = {
    'data-testid': 'brand-image',
    alt,
    src,
    loading,
    className,
    width: type === 'NARROW' ? SIZES[type] : SIZES.DEFAULT,
    height: 16,
  };

  if (!image) {
    return <Image {...props} />;
  }

  if ((image as ReactElement).props) {
    const imageElement = image as ReactElement;
    return cloneElement(imageElement, { ...props, ...imageElement.props });
  }
  const ImageComponent = image as ComponentType;
  const imageElement = <ImageComponent />;
  return cloneElement(imageElement, { ...props, ...imageElement.props });
};

BrandImage.displayName = 'BrandImage';
