import classnames from 'classnames';
import { type FunctionComponent } from 'react';

import styles from './TimeLeftLabel.module.scss';

export interface TimeLeftLabelProps {
  className?: string;
  value: string;
}

export const TimeLeftLabel: FunctionComponent<TimeLeftLabelProps> = ({
  className,
  value,
}) => {
  const rootClassnames = classnames(styles.root, className);

  return <div className={rootClassnames}>{value}</div>;
};

TimeLeftLabel.displayName = 'TimeLeftLabel';
