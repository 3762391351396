import classnames from 'classnames';
import { type FunctionComponent } from 'react';

import { InfoOutline as InfoIcon } from '@/core/tamagoshi/icons/InfoOutline';
import { Text } from '@/domains/core/tamagoshi/Text/Text';

import styles from './SponsoredLabel.module.scss';

export interface SponsoredLabelProps {
  label?: string;
  className?: string;
  onAdvertiserLabelClicked?(
    event: React.MouseEvent<HTMLElement, MouseEvent>,
  ): unknown;
}

export const SponsoredLabel: FunctionComponent<SponsoredLabelProps> = ({
  label,
  className,
  onAdvertiserLabelClicked,
}) => {
  const rootClassnames = classnames(styles.root, className);

  return (
    <div className={rootClassnames}>
      <button
        className={styles.button}
        onClick={
          onAdvertiserLabelClicked ? onAdvertiserLabelClicked : undefined
        }
        data-testid="info-label"
      >
        <Text tag="span" type="small" className={styles.root}>
          <span className={styles.label} suppressHydrationWarning>
            {label}
          </span>
          <InfoIcon className={styles.infoIcon} />
        </Text>
      </button>
    </div>
  );
};
SponsoredLabel.displayName = 'SponsoredLabel';
