import classnames from 'classnames';
import { type FunctionComponent, type MouseEvent } from 'react';

import { AddToCartButton } from '../AddToCartButton/AddToCartButton';
import {
  AddToCartState,
  type AddToCartStateProps,
} from '../AddToCartState/AddToCartState';

import styles from './AddToCartWrapper.module.scss';

export interface AddToCartWrapperProps {
  addToCartState: 'available' | AddToCartStateProps['state'];
  onAddToCart?(event: MouseEvent<HTMLButtonElement>): unknown;
  addToCartLabel: string;
  successLabel: string;
  errorLabel: string;
  className?: string;
  variant?: 'primary' | 'secondary';
  narrow?: boolean;
}

export const AddToCartWrapper: FunctionComponent<AddToCartWrapperProps> = ({
  addToCartState,
  onAddToCart,
  addToCartLabel,
  successLabel,
  errorLabel,
  className,
  variant = 'secondary',
  narrow = false,
}) => (
  <div className={classnames(styles.addToCartWrapper, className)}>
    {addToCartState === 'available' && (
      <AddToCartButton
        className={styles.addToCart}
        text={addToCartLabel}
        variant={variant}
        onClick={onAddToCart}
        displayMobileIcon={narrow}
        narrow={narrow}
      />
    )}
    {addToCartState === 'success' && (
      <AddToCartState state="success">{successLabel}</AddToCartState>
    )}
    {addToCartState === 'error' && (
      <AddToCartState state="error">{errorLabel}</AddToCartState>
    )}
  </div>
);
