import classnames from 'classnames';
import { type FunctionComponent } from 'react';

import styles from './LocalFlag.module.scss';

export interface LocalFlagProps {
  className?: string;
}

export const LocalFlag: FunctionComponent<LocalFlagProps> = ({ className }) => {
  const rootClassnames = classnames(styles.root, className);

  return <div className={rootClassnames} aria-label="made in France" />;
};

LocalFlag.displayName = 'LocalFlag';
