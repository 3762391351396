import type { Currency } from '@/domains/core/localization/interfaces/Currency';

type TamagoshiCurrency = 'EURO' | 'POUND_STERLING';

export const mapCurrency = (currency?: Currency): TamagoshiCurrency => {
  switch (currency) {
    case 'EUR':
      return 'EURO';
    case 'GBP':
      return 'POUND_STERLING';
    default:
      return 'EURO';
  }
};
