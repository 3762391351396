import classnames from 'classnames';
import { type FunctionComponent, type MouseEvent } from 'react';

import { AddShoppingCart } from '@/core/tamagoshi/icons/AddShoppingCart';
import { Button } from '@/domains/core/tamagoshiv2/components/Button/Button';

import styles from './AddToCartButton.module.scss';

export interface AddToCartButtonProps {
  text: string;
  className?: string;
  variant?: 'primary' | 'secondary' | 'tertiary';
  onClick?(event: MouseEvent<HTMLButtonElement>): unknown;
  displayMobileIcon?: boolean;
  narrow?: boolean;
}

export const AddToCartButton: FunctionComponent<AddToCartButtonProps> = ({
  text,
  className,
  variant = 'primary',
  displayMobileIcon = false,
  onClick,
  narrow,
}) => {
  const rootClassnames = classnames(
    styles.root,
    { [styles.displayMobileIcon]: displayMobileIcon },
    { [styles.narrow]: narrow },
    className,
  );
  return (
    <Button
      className={rootClassnames}
      content={{
        label: text,
        icon: () => <AddShoppingCart className={styles.iconButton} />,
      }}
      onClick={onClick}
      type={variant}
      width="fillContainer"
      size="L"
    />
  );
};

AddToCartButton.displayName = 'AddToCartButton';
