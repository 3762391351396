import classnames from 'classnames';
import { type FunctionComponent, type PropsWithChildren } from 'react';

import { AlertOutline } from '@/core/tamagoshi/icons/AlertOutline';
import { CheckCircleOutline } from '@/core/tamagoshi/icons/CheckCircleOutline';

import styles from './AddToCartState.module.scss';

export interface AddToCartStateProps extends PropsWithChildren {
  state: 'error' | 'success';
}

export const AddToCartState: FunctionComponent<AddToCartStateProps> = ({
  children,
  state,
}) => {
  const textStyle = classnames(styles.text, {
    [styles.success]: state === 'success',
    [styles.error]: state === 'error',
  });
  return (
    <div className={styles.root}>
      {state === 'success' && <CheckCircleOutline className={styles.success} />}
      {state === 'error' && <AlertOutline className={styles.error} />}
      <div className={textStyle}>{children}</div>
    </div>
  );
};
