import classnames from 'classnames';
import {
  cloneElement,
  type ComponentType,
  type FunctionComponent,
  type ReactElement,
} from 'react';

import { SIZES } from '../../constants';
import { type SizeType } from '../../interfaces';
import { Image, type ImageProps } from '../Image';

import styles from './ProductImage.module.scss';

export interface ProductImageProps {
  alt: string;
  src: string;
  className?: string;
  type?: SizeType;
  image?: ComponentType<ImageProps> | ReactElement<ImageProps>;
  loading: 'eager' | 'lazy';
  provideDataSrc: boolean;
}

export const ProductImage: FunctionComponent<ProductImageProps> = ({
  alt,
  src,
  className,
  image,
  type = 'DEFAULT',
  loading,
  provideDataSrc,
}) => {
  const props: ImageProps = {
    'data-testid': 'image',
    alt,
    src,
    loading,
    className: classnames(
      styles.root,
      {
        [styles.promoted]: type === 'PROMOTED',
      },
      className,
    ),
    width: SIZES[type],
    height: SIZES[type],
  };

  if (loading === 'lazy' && provideDataSrc) {
    props['data-src'] = src;
  }

  if (!image) {
    return <Image {...props} />;
  }
  if ((image as ReactElement).props) {
    const imageElement = image as ReactElement;
    return cloneElement(imageElement, {
      ...props,
      ...imageElement.props,
    });
  }
  const ImageComponent = image as ComponentType;
  const imageElement = <ImageComponent />;
  return cloneElement(imageElement, {
    ...props,
    ...imageElement.props,
  });
};

ProductImage.displayName = 'ProductImage';
