import { merchandisingLabels } from '@/core/tamagoshi/MerchandisingTag/interfaces';

import { type SizeType } from './components/VisualDetails/interfaces';
import { type Merchandising } from './interfaces';
import { DEFAULT_TAG, type ProductCardProps } from './ProductCard';

export const generateVisualDetailsType = <
  Tag extends React.ElementType = typeof DEFAULT_TAG,
>({
  narrow,
  light,
  vertical,
  merchandising,
}: Partial<ProductCardProps<Tag>>): SizeType =>
  (narrow && 'NARROW') ||
  (merchandising && merchandising.includes('promoted') && 'PROMOTED') ||
  (light && vertical && 'VERTICAL_LIGHT') ||
  (light && !vertical && 'LIGHT') ||
  'DEFAULT';

export const generateMerchandisingTags = (
  vertical?: boolean,
  light?: boolean,
  merchandising?: Merchandising[],
): Merchandising[] | undefined => {
  if (vertical && light) {
    if (merchandising?.includes('sponsored')) {
      return ['sponsored'];
    }
    if (merchandising?.includes(merchandisingLabels.COUPON)) {
      return [merchandisingLabels.COUPON];
    }
    if (merchandising?.includes(merchandisingLabels.BLACKFRIDAY)) {
      return [merchandisingLabels.BLACKFRIDAY];
    }
    if (merchandising?.includes(merchandisingLabels.SALES)) {
      return [merchandisingLabels.SALES];
    }
    if (merchandising?.includes(merchandisingLabels.MANOMANODAYS)) {
      return [merchandisingLabels.MANOMANODAYS];
    }
    if (merchandising?.includes(merchandisingLabels.PROMO)) {
      return [merchandisingLabels.PROMO];
    }
    if (merchandising?.includes(merchandisingLabels.LOWERED_PRICE)) {
      return [merchandisingLabels.LOWERED_PRICE];
    }
    return [];
  }
  return merchandising;
};

export const hasTag = (
  tags: Merchandising[],
  tagToCheck: Merchandising,
): boolean => tags.includes(tagToCheck);

export const hasAtLeastOneTag = (
  tags: Merchandising[],
  tagsToCheck: Merchandising[],
): boolean => tagsToCheck.some((tag) => hasTag(tags, tag));
