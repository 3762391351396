import classnames from 'classnames';
import { type FunctionComponent } from 'react';

import { InStock } from '@/core/tamagoshi/InStock/InStock';
import { merchandisingLabels } from '@/core/tamagoshi/MerchandisingTag/interfaces';
import { SponsoredLabel } from '@/core/tamagoshi/ProductCard/components/SponsoredLabel/SponsoredLabel';
import {
  hasAtLeastOneTag,
  hasTag,
} from '@/core/tamagoshi/ProductCard/ProductCard.utils';
import { TrendDownIcon } from '@/core/tamagoshiTailwind/icons/system/TrendDownIcon';

import { Tag } from '../../../Tag/Tag';
import {
  type Merchandising,
  type ProductCardTranslations,
} from '../../interfaces';

import styles from './MerchandisingTags.module.scss';

export interface MerchandisingTagsProps {
  className?: string;
  tags: Merchandising[];
  translations: ProductCardTranslations['tags'];
  onAdvertiserLabelClicked?(
    event: React.MouseEvent<HTMLElement, MouseEvent>,
  ): unknown;
}

export const MerchandisingTags: FunctionComponent<MerchandisingTagsProps> = ({
  className,
  tags,
  translations,
  onAdvertiserLabelClicked,
}) => {
  const rootClassnames = classnames(styles.root, className);

  const sponsoredClassNames = classnames(styles.item, {
    [styles.isSponsoredAndCouponOrPromo]: hasAtLeastOneTag(tags, [
      merchandisingLabels.COUPON,
      merchandisingLabels.PROMO,
    ]),
  });

  return (
    <div className={rootClassnames}>
      {hasTag(tags, 'promoted') && (
        <InStock
          className={styles.inStock}
          label={translations.inStock.label}
        />
      )}

      {hasTag(tags, merchandisingLabels.COUPON) &&
        translations.coupon?.label &&
        translations.coupon?.labelShort && (
          <Tag className={styles.item} color="blue">
            {hasTag(tags, 'sponsored')
              ? translations.coupon.labelShort
              : translations.coupon.label}
          </Tag>
        )}

      {hasTag(tags, merchandisingLabels.PROMO) &&
        !hasAtLeastOneTag(tags, [
          merchandisingLabels.BLACKFRIDAY,
          merchandisingLabels.SALES,
          merchandisingLabels.MANOMANODAYS,
        ]) && (
          <Tag className={styles.item} color="red">
            {translations.promo.label}
          </Tag>
        )}

      {hasTag(tags, merchandisingLabels.BLACKFRIDAY) &&
        translations.blackFriday?.label && (
          <Tag className={styles.item} color="black">
            {translations.blackFriday.label}
          </Tag>
        )}

      {hasTag(tags, merchandisingLabels.SALES) && translations.sales?.label && (
        <Tag className={styles.item} color="red">
          {translations.sales.label}
        </Tag>
      )}

      {hasTag(tags, merchandisingLabels.MANOMANODAYS) &&
        translations.manomanoDays?.label && (
          <Tag className={styles.item} color="red">
            {translations.manomanoDays.label}
          </Tag>
        )}

      {hasTag(tags, 'sponsored') && (
        <SponsoredLabel
          className={sponsoredClassNames}
          label={translations.sponsored.label}
          onAdvertiserLabelClicked={onAdvertiserLabelClicked}
        />
      )}

      {hasTag(tags, 'bestQualityOffer') && (
        <Tag className={styles.item} color="darkBlue">
          {translations.bestQualityOffer.label}
        </Tag>
      )}

      {hasTag(tags, 'topSale') && (
        <Tag className={styles.item} color="yellow">
          {translations.topSale.label}
        </Tag>
      )}

      {hasTag(tags, merchandisingLabels.LOWERED_PRICE) &&
        translations?.loweredPrice?.label && (
          <Tag
            className={classnames(styles.item, styles.loweredPrice)}
            color="red"
          >
            {translations.loweredPrice.label}
            <TrendDownIcon />
          </Tag>
        )}
    </div>
  );
};

MerchandisingTags.displayName = 'MerchandisingTags';
