import classnames from 'classnames';
import { type FunctionComponent } from 'react';

import styles from './RankLabel.module.scss';

export interface RankLabelProps {
  className?: string;
  rank: number;
}

export const RankLabel: FunctionComponent<RankLabelProps> = ({
  className,
  rank,
}) => {
  const rootClassnames = classnames(styles.root, className);

  return <div className={rootClassnames}>{`N°${rank}`}</div>;
};

RankLabel.displayName = 'RankLabel';
