import classnames from 'classnames';
import React, {
  forwardRef,
  RefAttributes,
  type ComponentType,
  type CSSProperties,
  type ReactElement,
  type ReactNode,
} from 'react';

import { merchandisingLabels } from '@/core/tamagoshi/MerchandisingTag/interfaces';
import { SecondHandTag } from '@/core/tamagoshi/SecondHandTag/SecondHandTag';
import { Rating } from '@/domains/core/tamagoshiTailwind/components/Rating/Rating';
import { rating as ratingTranslation } from '@/domains/core/tamagoshiTailwind/translations';

import {
  DetailedPrice,
  type DetailedPriceTranslations,
} from '../DetailedPrice/DetailedPrice';
import { AddToCartButton } from './components/AddToCartButton/AddToCartButton';
import {
  AddToCartWrapper,
  type AddToCartWrapperProps,
} from './components/AddToCartWrapper/AddToCartWrapper';
import {
  DeliveryLabel,
  type DeliveryLabelProps,
} from './components/DeliveryLabel/DeliveryLabel';
import { InformationLine } from './components/InformationLine/InformationLine';
import { LegalMention } from './components/LegalMention/LegalMention';
import { MerchandisingTags } from './components/MerchandisingTags/MerchandisingTags';
import { RankLabel } from './components/RankLabel/RankLabel';
import { TimeLeftLabel } from './components/TimeLeftLabel/TimeLeftLabel';
import { Title } from './components/Title/Title';
import { type ImageProps } from './components/VisualDetails/components/Image';
import { VisualDetails } from './components/VisualDetails/VisualDetails';
import { useProductCardErrors } from './hooks/useProductCardErrors';
import {
  type Currency,
  type Merchandising,
  type ProductCardTranslations,
} from './interfaces';
import {
  generateMerchandisingTags,
  generateVisualDetailsType,
  hasAtLeastOneTag,
} from './ProductCard.utils';

import styles from './ProductCard.module.scss';

export const DEFAULT_TAG = 'div' as const;

export interface ProductCardProps<
  Tag extends React.ElementType = typeof DEFAULT_TAG,
> extends React.PropsWithChildren {
  /**
   * title of the product
   */
  title: ReactNode;
  /**
   * Does the title have a custom formatting? (for highlighted words)
   */
  customTitleFormatting?: boolean;
  /**
   * The currency
   */
  currency: Currency;
  /**
   * main price
   */
  price: string | number;
  /**
   * User can use its own price component.
   */
  priceComponent?: ReactNode;
  /**
   * retail price is the crossed out price
   */
  retailPrice?: number;
  /**
   * An alternative price bellow main
   * (eg => main: 11€50/m2   secondary: 92€ per unit)
   */
  secondaryPrice?: string | number;
  /**
   * Unit to display for the secondary price
   * This unit is displayed after the price and tax informations and separated by a slash
   */
  secondaryUnit?: string;
  /**
   * A label to display the time left label before discount ending.
   * This props override `secondaryPrice` to `undefined`.
   */
  retailPriceTimeLeftLabel?: string;
  /**
   * product image source url
   */
  imageSrc: string;
  /**
   * User can use its own advanced Image component where he can manage lazyloading/placeholder.
   */
  imageComponent?: ComponentType<ImageProps> | ReactElement<ImageProps>;
  /**
   * User can use its own advanced Image component where he can manage lazyloading/placeholder.
   * This variant of imageComponent includes several images in a carousel
   */
  productImage?: ComponentType<ImageProps> | ReactElement<ImageProps>;
  /**
   * Indicates how the browser should load the image
   */
  imageLoading?: 'eager' | 'lazy';
  /**
   * Add data-src attribute in product image tag if imageLoading is `lazy`. It's
   * mainly used for seo
   */
  provideImageDataSrc?: boolean;
  /**
   * brand image source url
   */
  brandImageSrc?: string;
  /**
   * brand name text
   */
  brandName?: string;
  /**
   * display a text like "42 models for this product"
   */
  modelsCount?: number;
  /**
   * display rating starts
   */
  rating?: number;
  /**
   * display rating count in parentheses
   */
  ratingCount?: number;
  /**
   * Use in german platform to display legal price text
   */
  legalMention?: boolean;
  /**
   * Used in the German platform to display legal price text for products with 0 VAT instead of the usual legal mention
   */
  legalMentionNoVAT?: boolean;
  /**
   * Use to display specific displays on b2b platform. If it's true it take
   * `price` props as without VAT price and `secondaryPrice` prop as VAT price
   */
  b2b?: boolean;
  /**
   * Identify the product has selected for pro
   */
  pro?: boolean;
  /**
   * Used to display delivery information
   */
  delivery?: DeliveryLabelProps['deliveryLabel'];
  /**
   * Specify whenever a product card display merchandizing as tags.
   * Be careful, you cannot have more than two merchandizing values.
   * `bestQualityOffer` can not be used with others
   * `coupon` can not be used with others except when `promoted` is included
   */
  merchandising?: Merchandising[];
  /**
   * Fix the width of the product card to 295px.
   * Be careful, narrow is not compatible with merchandising `promoted` prop
   */
  narrow?: boolean;
  /**
   * Change the orientation of the product card to vertical
   */
  vertical?: boolean;
  /**
   * Change default size of image to 156x156 px
   *
   * Its used only on horizontal product card on all listings
   *
   * @deprecated this props is not used anymore, just the time to clean where
   * it's used
   */
  extraLargeImage?: boolean;
  /**
   * Change image size to 142x142 px
   *
   * Its used only on horizontal product card on all listings
   *
   * @deprecated this props is not used anymore, just the time to clean where
   * it's used
   */
  largeImagePlus?: boolean;
  /**
   * Change default size of image to 128x128 px
   *
   * @deprecated this props is not used anymore, just the time to clean where
   * it's used
   */
  largeImage?: boolean;
  /**
   * Change the display of the product card to light
   */
  light?: boolean;
  /**
   * Root element className
   */
  className?: string;
  /**
   * Root tag
   */
  tag?: Tag;
  /**
   * Component translations for legal mentions, models count and others
   */
  translations?: ProductCardTranslations;
  /**
   * Used to know if we should display delivery labels from separate components
   */
  displayDeliveryLabel?: boolean;
  /**
   * Used to display delivery labels from separate components
   */
  deliveryLabel?: DeliveryLabelProps['deliveryLabel'];
  /**
   * Used to display the add to cart wrapper component, only available with the vertical version of the ProductCard
   */
  addToCart?: boolean;
  /**
   * Skeleton to pass for the add to cart button.
   */
  addToCartSkeleton?: ReactNode;
  /**
   * Used to display a ribbon at the top left of the component with a rank value inside
   */
  rank?: number;
  /**
   * Used to tag a product as "made in a specific country" to underline its
   * local characteristic. For now only France is supported
   */
  madeIn?: 'fr';
  /**
   * Used to tag a product as following eco responsible principles
   */
  ecoResponsible?: boolean;
  /**
   * Used to know if we should display second hand tag
   */
  secondHand?: boolean;
  /**
   * Used to determine the add to cart wrapper component state, only available with the vertical version of the ProductCard
   */
  addToCartState?: AddToCartWrapperProps['addToCartState'];

  /**
   * Used to intercept clicks on ProductCard (ie : tracking, action dispatching…)
   */
  onClick?(event: React.MouseEvent<HTMLElement, MouseEvent>): void;

  /**
   * Used to intercept clicks on add to cart button (only on promoted)
   */
  onAddToCart?: AddToCartWrapperProps['onAddToCart'];

  /**
   * Allow custom inline css
   */
  style?: CSSProperties;

  /**
   * If you set a custom component as main tag, you can pass props to it by this
   * props
   */
  tagProps?: React.ComponentPropsWithoutRef<Tag>;
  /**
   * Price shown with or without including VAT
   * If it's 'withoutVAT' it takes `price` and `retail` props as without VAT price and `secondaryPrice` prop as VAT price
   * Else if it is 'VAT', `price` and `retail` props is displayed all taxes included and `secondaryPrice` prop as without VAT price
   * Undefined to show no tax label
   */
  tax?: 'withoutVAT' | 'VAT';
  /**
   * Add to cart button variant: 'primary' or 'secondary'
   */
  addToCartButtonVariant?: 'primary' | 'secondary';
  /**
   * seller name text
   */
  sellerName?: string;
  /**
   * ad payer text
   */
  adPayer?: 'BRAND' | 'SELLER';
  /**
   * Used to intercept clicks on sponsoredLabel (ie : tracking, action
   * dispatching…)
   */
  onAdvertiserLabelClicked?(
    event: React.MouseEvent<HTMLElement, MouseEvent>,
  ): unknown;
}

/**
 * The new product card can be full controlled by his props.
 */
function InnerProductCard<Tag extends React.ElementType = typeof DEFAULT_TAG>(
  props: ProductCardProps<Tag>,
  ref: React.ForwardedRef<React.ElementRef<Tag>>,
) {
  const {
    tag = DEFAULT_TAG,
    className,
    title,
    currency,
    price,
    priceComponent,
    secondaryPrice,
    secondaryUnit,
    retailPrice,
    retailPriceTimeLeftLabel,
    imageSrc,
    imageLoading = 'eager',
    secondHand = false,
    provideImageDataSrc = false,
    imageComponent,
    productImage,
    brandImageSrc,
    brandName,
    modelsCount = 1,
    rating = 0,
    ratingCount,
    legalMention = false,
    legalMentionNoVAT = false,
    narrow = false,
    vertical = false,
    translations,
    onClick,
    b2b = false,
    pro = false,
    delivery,
    merchandising,
    onAddToCart,
    light = false,
    customTitleFormatting = false,
    displayDeliveryLabel = false,
    deliveryLabel,
    addToCart = false,
    addToCartSkeleton,
    madeIn,
    ecoResponsible = false,
    addToCartState = 'available',
    tagProps,
    rank,
    tax,
    addToCartButtonVariant = 'secondary',
    onAdvertiserLabelClicked,
    ...otherProps
  } = props;

  useProductCardErrors(props);

  const isPro = b2b && pro;
  const hasRetailPriceTimeLeftLabel =
    retailPriceTimeLeftLabel !== undefined && retailPriceTimeLeftLabel !== '';
  const merchandisingTags = generateMerchandisingTags(
    vertical,
    light,
    merchandising,
  );

  const showMerchandisingTags = !!(
    merchandisingTags &&
    merchandisingTags.length > 0 &&
    translations
  );

  const promoted = merchandisingTags && merchandisingTags.includes('promoted');

  const type = generateVisualDetailsType(props);

  const rootClassNames = classnames(
    styles.root,
    {
      [styles.narrow]: narrow,
      [styles.vertical]: vertical,
      [styles.deliveryPlaceholder]: !displayDeliveryLabel && !delivery,
      [styles.promoted]: promoted,
      [styles.light]: light,
      [styles.rank]: !!rank,
    },
    className,
  );

  const contentContainerClassNames = classnames(styles.contentContainer, {
    [styles.hasTag]:
      merchandisingTags &&
      hasAtLeastOneTag(merchandisingTags, [
        'sponsored',
        merchandisingLabels.COUPON,
        merchandisingLabels.PROMO,
        merchandisingLabels.BLACKFRIDAY,
        merchandisingLabels.SALES,
        merchandisingLabels.MANOMANODAYS,
        merchandisingLabels.LOWERED_PRICE,
      ]),
  });

  const priceClassNames = classnames(styles.price, {
    [styles.isPromo]: merchandisingTags?.includes('promo'),
  });

  const translationForDetailedProps: DetailedPriceTranslations = {
    price: translations?.price,
  };

  return React.createElement(
    tag,
    {
      className: rootClassNames,
      ref,
      onClick,
      ...tagProps,
      ...otherProps,
    },
    <>
      {rank && <RankLabel className={styles.rankLabel} rank={rank} />}

      {secondHand && translations?.tags.secondHand && (
        <SecondHandTag
          className={styles.secondHandTag}
          label={translations.tags.secondHand.label}
          small
        />
      )}

      <VisualDetails
        className={styles.visualDetails}
        imageSrc={imageSrc}
        brandImageSrc={brandImageSrc}
        brandName={brandName}
        productTitle={typeof title === 'string' ? title : ''}
        image={imageComponent}
        productImage={productImage}
        vertical={vertical}
        light={light}
        type={type}
        imageLoading={imageLoading}
        provideImageDataSrc={provideImageDataSrc}
        flag={madeIn}
        ecoTagEnabled={ecoResponsible}
      />

      <div className={contentContainerClassNames}>
        {showMerchandisingTags && merchandisingTags && (
          <MerchandisingTags
            className={styles.merchandising}
            tags={merchandisingTags}
            translations={translations?.tags as ProductCardTranslations['tags']}
            onAdvertiserLabelClicked={onAdvertiserLabelClicked}
          />
        )}
        {typeof title === 'string' ? (
          <Title
            text={title}
            shortenTitle={light || (merchandisingTags || []).length > 0}
            className={styles.title}
            customTitleFormatting={customTitleFormatting}
          />
        ) : (
          <div className={styles.title}>{title}</div>
        )}
        {!light && translations && (
          <InformationLine
            modelsCount={modelsCount}
            className={styles.informationLine}
            translations={translations.informationLine}
          />
        )}

        <div className="my-xs flex h-[16px]">
          {!!ratingCount && ratingCount > 0 ? (
            <Rating
              size="S"
              value={rating}
              aria-label={ratingTranslation.ariaLabel}
              reviewsCount={ratingCount}
            />
          ) : null}
        </div>

        {priceComponent || (
          <>
            <DetailedPrice
              className={priceClassNames}
              currency={currency}
              value={price}
              secondaryValue={
                !hasRetailPriceTimeLeftLabel ? secondaryPrice : undefined
              }
              secondaryUnit={secondaryUnit}
              tax={tax}
              retailValue={retailPrice}
              retailAbove={!!(b2b && retailPrice)}
              proLabel={isPro}
              placeholderDisabled={light && vertical}
              translations={translationForDetailedProps}
            />
            {hasRetailPriceTimeLeftLabel && !vertical && !light && (
              <TimeLeftLabel
                value={retailPriceTimeLeftLabel as string}
                className={styles.timeLeftLabel}
              />
            )}
          </>
        )}
        {legalMention && translations && (
          <LegalMention
            text={
              (legalMentionNoVAT && translations.legalMention.mentionNoVAT) ||
              translations.legalMention.mention
            }
          />
        )}
        {promoted && translations && (
          <AddToCartButton
            className={styles.addToCart}
            text={translations.addToCart.label}
            onClick={onAddToCart}
            displayMobileIcon
          />
        )}

        {((light && displayDeliveryLabel && delivery) ||
          (!light && delivery)) &&
          translations && (
            <DeliveryLabel
              className={styles.delivery}
              deliveryLabel={delivery}
              translations={translations.deliveryLabel}
              isPro={b2b}
            />
          )}
        {!addToCartSkeleton && vertical && addToCart && translations && (
          <AddToCartWrapper
            addToCartState={addToCartState}
            onAddToCart={onAddToCart}
            addToCartLabel={translations.addToCart.label}
            successLabel={translations.addToCart.success}
            errorLabel={translations.addToCart.error}
            className={styles.addToCartWrapper}
            variant={addToCartButtonVariant}
            narrow={narrow}
          />
        )}
        {vertical && addToCart && addToCartSkeleton}
      </div>
    </>,
  );
}

export const ProductCard = forwardRef(InnerProductCard) as <
  Tag extends React.ElementType = typeof DEFAULT_TAG,
>(
  props: ProductCardProps<Tag> & RefAttributes<React.ElementRef<Tag>>,
) => ReactElement;

(ProductCard as React.FC).displayName = 'ProductCard';
