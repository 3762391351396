import classnames from 'classnames';
import { type FunctionComponent } from 'react';

import { Text } from '../../../Text/Text';

import styles from './Title.module.scss';

export interface TitleProps {
  text: string;
  shortenTitle?: boolean;
  className?: string;
  customTitleFormatting?: boolean;
}

const NON_BREAKING_SPACE = ' ';
const BREAKING_SPACE = ' ';
const NON_BREAKING_SPACE_REGEXP = new RegExp(NON_BREAKING_SPACE, 'g');

export const Title: FunctionComponent<TitleProps> = ({
  text,
  className,
  shortenTitle = false,
  customTitleFormatting = false,
}) => {
  const titleClassNames = classnames(styles.root, className, {
    [styles.shortTitle]: shortenTitle,
  });

  const formattedText = text.replace(NON_BREAKING_SPACE_REGEXP, BREAKING_SPACE);

  if (customTitleFormatting) {
    return (
      <Text
        type="body2"
        weight="regular"
        className={titleClassNames}
        dangerouslySetInnerHTML={{ __html: formattedText }}
        data-testid="title-with-custom-formatting"
      />
    );
  }

  return (
    <Text type="body2" weight="semiBold" className={titleClassNames}>
      {formattedText}
    </Text>
  );
};

Title.displayName = 'Title';
