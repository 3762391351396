import classnames from 'classnames';
import { type FunctionComponent } from 'react';

import { Text } from '../../../Text/Text';

import styles from './LegalMention.module.scss';

export interface LegalMentionProps {
  text: string;
  className?: string;
}

export const LegalMention: FunctionComponent<LegalMentionProps> = ({
  text,
  className,
}) => {
  const rootClassnames = classnames(styles.root, className);
  return (
    <Text
      className={rootClassnames}
      tag="span"
      type="small"
      data-testid="legal-notice-text"
    >
      {text}
    </Text>
  );
};

LegalMention.displayName = 'LegalMention';
