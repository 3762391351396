import classnames from 'classnames';
import { type FunctionComponent } from 'react';

import { type ProductCardTranslations } from '../../interfaces';
import { AlternativeModel } from './AlternativeModel';

import styles from './InformationLine.module.scss';

export interface InformationLineProps {
  modelsCount?: number;
  className?: string;
  translations: ProductCardTranslations['informationLine'];
}

export const InformationLine: FunctionComponent<InformationLineProps> = ({
  modelsCount,
  className,
  translations,
}) => {
  const rootClassNames = classnames(styles.root, className);
  // Add here Attributes display logic here
  return (
    <div data-testid="information-line" className={rootClassNames}>
      {!!modelsCount && modelsCount > 1 && translations?.alternativeModel && (
        <AlternativeModel label={translations.alternativeModel} />
      )}
    </div>
  );
};

InformationLine.displayName = 'InformationLine';
